
import React from 'react'
import './Beautysalon.css'
import App from './crousel'
import Card from './BeautyCard';
import Servicecard from './Servicecard';
import BeautyContact from './BeautyContact';
import Package from './Package';
function Beautysalon() {
  return (
    <div className='head' id='Home'>
      <div className='home_div'>
        <App />

      </div>
      <div className='Shop_div'>
        <Card />
      </div>

      <div id='Services' name='services' className='Service_div'>
        <Servicecard />
      </div>
      <div className='Pakage_div' id='Package' name="package">

        <Package />
      </div>

      <div className='Contect_div' name='contact'>
        
        <BeautyContact className='C_contact' />
      </div>
    </div>
  )
}

export default Beautysalon