import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './SalonBeauty/App';
// import App from './Header.js';
// import Profile from './React_task';
// import Profile from './App';
// import App from './Beautysalon'
// import App from './Task2'
// import App from './task3';
// import Profile from './Myproject';
// import App from './Myprojectcollaps';
//  import App from './Beautysaloan'
// import App from './Travel'
// import App  from './Beautyheader';
// import App from './Beautyfooter'
// import App from './Dashbaord'
// import App from './Parent'
// import App from './Child_data'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   // <Profile></Profile>
   <App></App>
);

