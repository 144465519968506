import React from 'react'
import { useLocation } from 'react-router-dom';
import {Button } from 'antd';
import { useState } from 'react';
import './apProduct.css';
function Product() {
  const location = useLocation();
  const [count, setCount] = useState(0)
  console.log(count)
  const Counter = () => {
      setCount(count + 1)
      console.log(count)
  }

  return (
    <div className='main_div1'>
      <br />
      <h1 style={{color:'black',textAlign:'center'}}>{location.state.CateogryName}</h1>

      <hr />
      <p style={{color:'GrayText',textAlign:'center'}}>{location.state.type} <span style={{color:'black'}}>Total cart:{count}</span></p>
      <div className='about'>



        <div className='category_detail'>

          {location.state.item.map((i) => (
            <>
              <img src={i.images} alt='product' className='a_img_p' />
              <br />
              <br />
             
            </>
          ))}

        </div>
        <div className='category_detail1'>
        {location.state.item.map((i) => (
            <>
              
             
              <h2 className='title_p'>{i.name}</h2>
             <p3>Price:{i.price}</p3><Button onClick={Counter}>Add To Cart</Button>
              <h4 className='hide-on-small-screen'>Product Details:</h4>
              <p className='hide-on-small-screen'>{i.description}</p>
            </>
          ))}
        </div>

      </div>
    </div>
  )
}

export default Product