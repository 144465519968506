// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-h69U7j6LgSJfkwexKpfR_6jJCjjYrem0lg&usqp=CAU"} className="App-logo" alt="logo"  />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
// // import React from 'react'

// // function App() {
// //   return (
// //     <div>

// //     </div>
// //   )
// // }

// export default App
// import React from "react";
// import ReactDOM from "react-dom/client";
// import { BrowserRouter, Routes, Route} from "react-router-dom";
// import Header from './Beautyheader'
// import About from './Beautysaloan'
// import Home from'./Beautysalon'
// import Product from './apProduct';
// import Shop from './Shop';
// import Team from './Beautyteam';
// import Appointment from './Appoiment'
// export default function App(){
//   return (
//     <BrowserRouter>
//    <Header/>
//     <Routes>
//         <Route path="/" element={<Home/>} />
//       </Routes>

//        <Routes>
//       <Route path='/About' element={<About/>} />
//       </Routes>
//       <Routes>
//       </Routes>
//       <Routes>
//       <Route path="/Product" element={<Product />} />
//       </Routes>
//       <Routes>
//         <Route path='/Shop' element={<Shop/>} />
//       </Routes>
//       <Routes>
//         <Route path='/Team' element={<Team/>} />
//       </Routes>
//       <Routes>
//         <Route path='/Appointment' element={<Appointment/>} />
//       </Routes>
//     </BrowserRouter>
//   );
// }

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Home from './Home';
// import Login from "./Login";
// import './App.css'
import Navbar from "./Navbar";
import About from './Beautysaloan'
import Home from './Beautysalon'
import Product from './apProduct';
import Package from './Package';
import Shop from './Shop';
import Servicecard from './Servicecard';
import Team from './Beautyteam';
import Appointment from './Appoiment'
import Footer from './Beautyfooter'
export default function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Routes>
        <Route path='/About' element={<About />} />
      </Routes>
      <Routes>
        <Route path='/services' element={<Servicecard />} />
      </Routes>
      <Routes>
        <Route path="/Package" element={<Package />} />
      </Routes>
      <Routes>
        <Route path="/Product" element={<Product />} />
      </Routes>
      <Routes>
        <Route path='/Shop' element={<Shop />} />
      </Routes>
      <Routes>
        <Route path='/Team' element={<Team />} />
      </Routes>
      <Routes>
        <Route path='/appoinment' element={<Appointment />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);