import React from 'react'
import './Package.css'
import './Beautysalon.css'
import img1 from './Image/hydra facial.png'
import img2 from './Image/menicure.png'
import img3 from './Image/Pedicure.png'
import img5 from './Image/nail art.png'
import img6 from './Image/eye makeup.jpeg'
import img7 from './Image/Beautybg4.jpeg'
import img8 from './Image/Beautybg2.jpg'
import img10 from './Image/waxing.jpeg'
function Package() {
  return (
    <div className='package_super_main'>
      <br />
      <h1 className='h111'>Services Package</h1>
      <br />
    <div className='Pakage_main'>
      <div className='Pakage_main_div'>
        <div className='fdiv'>
          <img className='pack_img' alt='' width="100%" src={img1} />
          <div>
            <h3 className='price'>Facial &emsp;&emsp;&emsp;&emsp;&emsp;130.0$</h3>
            <p></p>
          </div>
        </div>
        <div className='fdiv'>
          <img className='pack_img' alt='' width="100%" src={img2} />
          <div>
            <h3 className='price'>Nail Art&emsp;&emsp;&emsp;&emsp;&emsp;95.0$</h3>
            <p></p>
          </div>
        </div>
        <div className='fdiv'>
          <img className='pack_img' alt='' width="100%" src={img3} />
          <div>
            <h3 className='price'>Pedicure &emsp;&emsp;&emsp;&emsp;230.0$</h3>
            <p></p>
          </div>
        </div>
        <div className='fdiv'>
          <img className='pack_img' alt='' width="100%" src={img5} />
          <div>
            <h3 className='price'>Menicure &emsp;&emsp;&emsp;&emsp;230.0$</h3>
            <p></p>
          </div>
        </div>
      </div>
      <div className='Pakage_main_div2'>
        <div className='f2div'>
          <img className='pack_img' alt='' width="100%" src={img6} />
          <div>
            <h3 className='price'>Eye Makeup &emsp;&emsp; &emsp;85.0$</h3>
            <p></p>
          </div>
        </div>
        <div className='f2div'>
          <img className='pack_img' alt='' width="100%" src={img7} />
          <div>
            <h3 className='price'>Bridal Makeup &emsp;1130.0$</h3>
            <p></p>
          </div>
        </div>
        <div className='f2div'>
          <img className='pack_img' alt='' width="100%" src={img8} />
          <div>
            <h3 className='price'>Party Makeup &emsp;1000.0$</h3>
            <p></p>
          </div>
        </div>
        <div className='f2div'>
          <img className='pack_img' alt='' width="100%" src={img10} />
          <div>
            <h3 className='price'>Waxing     &emsp;&emsp;  &emsp;&emsp;440.0$</h3>
            <p></p>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Package