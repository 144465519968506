import React from 'react'


// import { useLocation } from 'react-router-dom';
// import { useState } from 'react';

import { Button, Card, Image, } from "antd"
import layoutImg1 from './Image/nailcare.jpeg';
import layoutImg2 from './Image/skin.jpeg';
import layoutImg3 from './Image/lip.jpeg';
import layoutImg4 from './Image/makeupbox.jpeg';
import { useNavigate } from 'react-router-dom';

import icon1 from './Image/11.jpg-removebg-preview.png';
import icon2 from './Image/12-removebg-preview.png'
import './Shop.css'
// import { CiLocationOn } from "react-icons/ci";
// import { FiPhoneCall } from "react-icons/fi"
// import { FaSquareXTwitter } from "react-icons/fa6";
// import { FaFacebook } from "react-icons/fa";
// import { IoLogoInstagram } from "react-icons/io";
// import logo from './LOGO.jpg';
import bg from './Image/bg_shop.jpeg'
import nail1 from './Image/nail1.jpeg'
import nail2 from './Image/nail2.jpeg'
import makeup1 from './Image/makeup1.jpeg'
import makeup2 from './Image/makeup2.jpeg'
import lip1 from './Image/lip1.jpg'
import lip2 from './Image/lip2.jpeg'
import org1 from './Image/org1.jpg'
import org2 from './Image/org2.jpeg'

// import pink from './pink-bg.jpeg'

function Shop() {
    // const location = useLocation();
    // const [count, setCount] = useState(0)
    // console.log(count)
    // const Counter = () => {
    //     setCount(count + 1)
    //     console.log(count)
    // }
    const navigate = useNavigate();
    const Data = [
        {
            id: 1,
            CateogryName: 'Discover Nailcare',
            type: '2 Product',

            img: layoutImg1,
            item: [
                {
                    images: nail1,
                    name: "Vegan Nail Gel -  (10 ml)",
                    price: '₹300',
                    description: ' This long-lasting nail polish can be cured under the UV light lamp and delivers a high-shine color that lasts for up to 21 days without any chipping, touch-ups, or maintenance. '
                },
                {
                    images: nail2,
                    name: "Vegan Nail Polish - Rosewood (10 ml)",
                    price: '₹500',
                    description: 'This long-lasting nail polish can be cured under the UV light lamp and delivers a high-shine color that lasts for up to 21 days without any chipping, touch-ups, or maintenance. '
                }
            ]
        },
        {
            id: 2,
            CateogryName: 'Beauty of Skin',
            type: '2 Product',

            img: layoutImg2,
            item: [
                {
                    images: makeup1,
                    name: " Matte Foundation - 02 (30ml)",
                    price: '₹1,066',
                    description: 'This matte foundation minimizes skin pores and offers buildable coverage for a smooth finish.  '
                },
                {
                    images: makeup2,
                    name: "York Fit Me Compact Powder - 310 Sun Beige (8g)",
                    price: '₹244',
                    description: 'This lightweight compact powder offers full coverage without settling into pores for a clear look. '
                }
            ]
        },
        {
            id: 3,
            CateogryName: 'Awesome Lip Care',
            type: '2 Product',

            img: layoutImg3,
            item: [
                {
                    images: lip1,
                    name: "York   Matte Lipstick - e (3.9g)",
                    price: '₹279',
                    description: ' This highly-pigmented lipstick comes with a creamy texture and ensures long-lasting wear. '
                },
                {
                    images: lip2,
                    name: "Berry Lip Glowy Balm (10g)",
                    price: 'Rs.500',
                    description: 'This moisturizing lip balm is made of murumuru butter and shea butter to lock in moisture content while preventing chapped lips. '
                }
            ]
        },
        {
            id: 4,
            CateogryName: 'Makeup Organizer',
            type: '2 Product',

            img: layoutImg4,
            item: [
                {
                    images: org1,
                    name: "BFF Makeup Brush Organizer (1Pc)",
                    price: '₹2,495',
                    description: ' This premium-quality makeup organizer comes with 8 compartments to store all your makeup brushes with ease. '
                },
                {
                    images: org2,
                    name: "Makeup Brush Organizer (1Pc)",
                    price: '₹2,495',
                    description: 'This premium-quality makeup organizer comes with 8 compartments to store all your makeup brushes with ease.  '
                }
            ]
        }
    ]
    const Demo = (id) => {
        console.log(id, "*");
        const property = Data.find(item => item.id === id);
        console.log(property)
        navigate('/Product', { state: property })
    }
    return (
        <div>

            <div className='shop1_div'>
                <img className='bg_img' src={bg} alt="bg" />
                <p className='p_shop'>Shop </p>
                <div className='container-div'>
                    <div className='icon_shop'>
                        <img src={icon1} alt={'icon_image'} className='icon1' />
                    </div>
                    <div style={{ marginLeft: '0vh' }}>
                        <p style={{ fontSize: '2vh', marginTop: '1vh', fontWeight: 'bold' }}> High-end</p>
                        <p style={{ fontSize: '2vh', marginTop: '2vh', fontWeight: 'bold' }}>Cosmetics</p>
                    </div>
                    <hr color='black' ></hr>
                    <div className='icon_shop'>
                        <img src={icon2} alt={'icon_image'} className='icon2' />
                    </div>

                    <div >
                        <p style={{ fontSize: '2vh', marginTop: '1vh', fontWeight: 'bold' }}> Express</p>
                        <p style={{ fontSize: '2vh', marginTop: '2vh', fontWeight: 'bold' }}>Beauty</p>
                    </div>
                </div>
            </div>
            <div className='collection_div'>
                <p className='p_collection'>Product Collection</p>
                <h1 className='p_discover'>Discover our products</h1>
                
                {Data.map((i) => (
                   
                    <Card className='p_card'>
                        {/* <Image src={i.img}  style={{height:'400'}}/> */}
                        <Image
                            width={200} height={300}
                            src={i.img} className='img'
                        />
                        <br /><br />
                        <h3 className='cat_detail'>  {i.CateogryName}</h3>
                        <p className='cat_detail'> {i.type}</p>

                        <Button className="button_view" style={{ margin: ' auto', marginTop: '-1vh' }} onClick={() => Demo(i.id)}>View More</Button>
                        <br />
                    </Card>
                ))}

            </div>
            <div className='S_Container_div'>
                <br/>
                <div className='pro_div1'>
                    <div className='fir_div'>
                        <div className='img_div'>


                            <Image
                                width={200} height={200} alt={'featured image'}
                                src={makeup2} className='img'
                            />
                        </div>
                        <div class="cont_div">
                            <h3 >
                                <a href="/shop" style={{ textDecoration: 'none', color: 'black', fontSize: 'medium' }}>Matte Powder <br /> Compact</a></h3>
                            <p style={{ color: 'darkslategray', paddingLeft: '3vh', paddingRight: '3vh', fontSize: 'small', marginTop: '-10px' }}>Molestias internos et commodi tempora dolores sapiente sed iste.</p>
                            <div className='span_div'>
                                <span style={{ color: 'darkgoldenrod' }}>Save Rs.50</span><br />


                            </div>
                        </div>
                    </div >
                    <div className='fir_div'>
                        <div className='img_div'>


                            <Image
                                width={200} height={200} alt={'featured image'}
                                src={nail2} className='img'
                            />
                        </div>
                        <div class="cont_div">
                            <h3 >
                                <a href="/shop" style={{ textDecoration: 'none', color: 'black', fontSize: 'medium' }}>Matte Liquid <br /> Nail Polish</a></h3>
                            <p style={{ color: 'darkslategray', paddingLeft: '3vh', paddingRight: '3vh', fontSize: 'small', marginTop: '-10px' }}>Molestias internos et commodi tempora dolores sapiente sed iste.</p>
                            <div className='span_div'>
                                <span style={{ color: 'darkgoldenrod' }}>Save Rs.50</span>
                            </div>
                        </div>

                    </div>
                    <div className='fir_div'>
                        <div className='img_div'>


                            <Image
                                width={200} height={200} alt={'featured image'}
                                src={lip1} className='img'
                            />
                        </div>
                        <div class="cont_div">
                            <h3 >
                                <a href="/shop" style={{ textDecoration: 'none', color: 'black', fontSize: 'medium' }}>Matte Liquid <br /> Lipstick</a></h3>
                            <p style={{ color: 'darkslategray', paddingLeft: '3vh', paddingRight: '3vh', fontSize: 'small', marginTop: '-10px' }}>Molestias internos et commodi tempora dolores sapiente sed iste.</p>
                            <div className='span_div'>
                                <span style={{ color: 'darkgoldenrod' }}>Save Rs.50</span>
                            </div>
                        </div>
                    </div>
                    <div className='fir_div'>
                        <div className='img_div'>


                            <Image
                                width={200} height={200} alt={'featured image'}
                                src={org1} className='img'
                            />
                        </div>
                        <div class="cont_div">
                            <h3 >
                                <a href="/shop" style={{ textDecoration: 'none', color: 'black', fontSize: 'medium' }}>Makeup  <br /> Organizer </a></h3>
                            <p style={{ color: 'darkslategray', paddingLeft: '3vh', paddingRight: '3vh', fontSize: 'small', marginTop: '-10px' }}>Molestias internos et commodi tempora dolores sapiente sed iste.</p>
                            <div className='span_div'>
                                <span style={{ color: 'darkgoldenrod' }}>Save Rs.50</span>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>

        </div>
    )
}

export default Shop