
import React, { useState, useEffect } from 'react';
import { Card, Button } from 'antd';
import './Servicecard.css';
import './Beautysalon.css'
import img1 from './Image/hydra facial.png';
import img2 from './Image/menicure.png';
import img3 from './Image/Pedicure.png';
import img5 from './Image/nail art.png';
import img6 from './Image/eye makeup.jpeg';
import img7 from './Image/Beautybg4.jpeg';
import img8 from './Image/Beautybg2.jpg';
import img9 from './Image/waxing.jpeg';

function Service() {
    const Data = [
        { "title": "Facial", "img": img1 },
        { "title": "Nail Art", "img": img2 },
        { "title": "Pedicure", "img": img3 },
        { "title": "Menicure", "img": img5 },
        { "title": "Party Makeup", "img": img6 },
        { "title": "Bridel Makeup", "img": img7 },
        { "title": "Eye Makeup", "img": img8 },
        { "title": "Body Waxing", "img": img9 }
    ];

    const [visibleCards, setVisibleCards] = useState(window.innerWidth >= 1024 ? Data.length : 4);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

    useEffect(() => {
        const handleResize = () => {
            const isNowDesktop = window.innerWidth >= 1024;
            setIsDesktop(isNowDesktop);
            setVisibleCards(isNowDesktop ? Data.length : 4);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const showMore = () => setVisibleCards(prev => Math.min(prev + 4, Data.length));
    const showLess = () => setVisibleCards(prev => Math.max(prev - 4, 4));

    return (
        <div className='Sevices_bg_div'>
           
            <h1 className='h111'>Our Services</h1>
            <div className='Service_main'>
                {Data.slice(0, visibleCards).map((i, index) => (
                    <Card className='card_S' key={index}>
                        <div className="custom-image_S">
                            <img className='S_img' alt={i.title} width="100%" src={i.img} />
                        </div>
                        <div className="custom-card_S">
                            <h2 className='bn_S'>{i.title}</h2>
                        </div>
                    </Card>
                ))}
            </div>
            {!isDesktop && (
                <div className="buttons">
                    {(visibleCards < Data.length) && (
                        <Button className="button_view" onClick={showMore}>View More</Button>
                    )}
                    {(visibleCards > 4) && (
                        <Button className="button_view" onClick={showLess}>View Less</Button>
                    )}
                </div>
            )}
        </div>
    )
}

export default Service;
