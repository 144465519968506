// import React from 'react';
// import './Beauty_footer.css';
// import F_img1 from './Image/loc1.png'
// import F_img2 from './Image/call1.png'
// import F_img3 from './Image/mail1.png'
// import F_img4 from './Image/insta1.png'
// import F_img5 from './Image/facebook.png'
// import F_img6 from './Image/youtube.png'
// import F_img7 from './Image/twitter.png'
// function Beautyfooter() {
//   return (
//         <div>
//        <div className='main_footer' >
//         <div className='footer'>
//           <div className='f_div1'>
//             <p className='h4'>Get In Touch</p>
//             <p className='f_p'>< img src={F_img1} alt='location' className='F_img1'/>&nbsp;Lily Chowk,Puranibasti Raipur (C.G)</p>
//             <p className='f_p'><img src={F_img2} alt='location' className='F_img1'/>&nbsp;222 333 4444</p>
//             <p className='f_p'><img src={F_img3} alt='location' className='F_img1'/>&nbsp;beautysalon@gmail.com</p>

//           </div>
//           <div className='f_div2'>
//           <p className='h4'>Quik Links</p>
//               <a href='/#' className='f_a'><p className='f_p' >Services</p></a>
//               <a href='/#' className='f_a'><p className='f_p'>Home</p></a>
//               <a href='/#' className='f_a'><p className='f_p'>About</p></a>
//           </div>
//           <div className='f_div3'>
//           <p className='h4'>Opening Hours</p>
//             <p className='f_p'>Monday-Saturday: 11:30-10:00</p>
//             <p className='f_p'>Sunday: 11:00-7:00</p>
//           </div>
//            <div className='f_div4'>
//           < img src={F_img4} alt='location' className='F_img2'/>
//           < img src={F_img5} alt='location' className='F_img2'/>
//           < img src={F_img6} alt='location' className='F_img2'/>
//           < img src={F_img7} alt='location' className='F_img2'/>

//           </div>
        
//         </div>
//         <hr></hr>
//             <div className='sub_footer'>
//               <p> @{new Date().getFullYear()} .All rights reversed.</p>
//             </div>
//      </div>  
//      </div>
//   )
// }
// export default Beautyfooter
import React from 'react';
import './Beauty_footer.css';
import F_img1 from './Image/loc1.png'
import F_img2 from './Image/call1.png'
import F_img3 from './Image/mail1.png'
import F_img4 from './Image/insta1.png'
import F_img5 from './Image/facebook.png'
import F_img6 from './Image/youtube.png'
import F_img7 from './Image/twitter.png'
import { Card, Space } from 'antd';

const App  = () => (
  <div>
  <Space   className='Bfooter'>
    <Card title="Get In Touch"  className='F_card'  style={{ width: 300 }}>
    <p className='f_p'>< img src={F_img1} alt='location' className='F_img1'/>&nbsp;Lily Chowk,Puranibasti Raipur (C.G)</p>
    <p className='f_p'><img src={F_img2} alt='location' className='F_img1'/>&nbsp;222 333 4444</p>
    <p className='f_p'><img src={F_img3} alt='location' className='F_img1'/>&nbsp;beautysalon@gmail.com</p>
    </Card>
    <Card title="Quik Links" className='F_card'  style={{ width: 300 }}>
      <a href='/#' className='f_a'><p className='f_p' >Services</p></a>
      <a href='/#' className='f_a'><p className='f_p'>Home</p></a>
      <a href='/#' className='f_a'><p className='f_p'>About</p></a>
    </Card>
    <Card  title="Opening Hours"   className='F_card3' style={{ width: 300 }}>
      <p className='f_p'>Monday-Saturday: 11:30-10:00</p>
      <p className='f_p'>Sunday: 11:00-7:00</p>
    </Card>
    <Card title="Social Link" className='F_card4' style={{ width: 300 }}>
    < img src={F_img4} alt='location' className='F_img2'/>
           < img src={F_img5} alt='location' className='F_img2'/>
           < img src={F_img6} alt='location' className='F_img2'/>
          < img src={F_img7} alt='location' className='F_img2'/>
    </Card>
    
  </Space>
 

  <div className='sub_footer'>
  
    <p > @{new Date().getFullYear()} .All rights reversed.</p>
    </div>
 
    
  </div>
);

export default App;