
import React, { useState } from 'react'
import emailjs from '@emailjs/browser'
import './BeautyContact.css'
import './Beautysalon.css'
import { Button } from 'antd';

function Form() {
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const handleSubmit = (e) => {
        alert("Your mail is send successully")
        console.log("success");
        e.preventDefault();
        // your emailjs serice Id ,template Id , and public key
        const serviceId = 'service_7okuggl';
        const temlateId = 'template_2cj8tjn';
        const publicKey = 'H4j2VW1RTqkXEi73p';
        // create a new object that contains dynamic template params
        const templateParams = {
            from_name: name,
            from_email: email,
            from_number: contact,
            to_name: 'Prerna Vinayak',
            message: message,
        };
        // Send the email using EmailJS
        emailjs.send(serviceId, temlateId, templateParams, publicKey)
            .then((response) => {
                console.log("Email sent Successfully!", response);
                setName('');
                setEmail('');
                setContact('');
                setMessage('');

            })
            .catch((error) => {
                console.error("Error sending email;", error)
            });
    }

    return (
        <div id='contact' className='c_super_maindiv'>
            <h1 className='h111'>CONTACT OUR TEAM</h1>

            <div className='c_maindiv'>

                <div className='Form_div' >


                    <form onSubmit={handleSubmit} className='c_form_c' action="#">

                        <h2 className='c_leave_h'>CONTACT US</h2>
                        <input className='c_input_i' type="text" value={name} 
                        onChange={(e)=>setName(e.target.value)}
                        placeholder=" Your Name" /><br />
                        <br />
                        <input className='c_input_i'
                        onChange={(e)=>setEmail(e.target.value)} type="email" value={email} placeholder="Your Email " /><br />
                        <br />
                        <input className='c_input_i' 
                        onChange={(e)=>setContact(e.target.value)}type="contact" value={contact} placeholder="Your Number" /><br />
                        <br />
                        <textarea className='c_input_text' 
                        onChange={(e)=>setMessage(e.target.value)} value={message} placeholder="Your Message"></textarea>

                        <Button  htmlType="submit" type="primary" className='c_button_get'>Submit</Button>
                        <br />
                        <br />
                    </form>

                </div>

                <br />
                <br />
            </div>
        </div>
    )
}

export default Form;