import React from 'react'
import { Card } from 'antd';
import './Beautyteam.css'
import img1 from './Image/a1.png'
import img2 from './Image/a2.jpeg'
import img3 from './Image/a3.jpeg'
import img4 from './Image/a4.jpeg'
function Beautyteam() {
  return (
    <div className='team_div'>
                    <h1 className='team'>Our Beauty Team</h1>
        <div className='maint'>
                <Card className='Card1t' style={{ width: '20%', padding: 0, height: '55vh' }}>
                    <div className="custom-imaget">
                    <img  className='Imgt'src={img1} alt=''/>
                    <p className='P2t'>SONAL SINGH</p>
                    </div>
                    <div className="custom-cardt">
                        <h4>BEAUTICIAN</h4>
                        <p className='Pt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, libero corrupti. Nemo quis sint commodi nulla praesentium recusandae.</p>
                    </div>
                </Card>
                <Card className='Card1t' style={{ width: '20%', padding: 0, height: '55vh'  }}>
                    <div className="custom-imaget">
                    <img className='Imgt' src={img2} alt=''/>
                    <p className='P3t'>AHUTI SONI</p>
                    </div>
                    <div className="custom-cardt">
                        <h4>MAKEUP ARTIST</h4>
                        <p className='Pt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, libero corrupti. Nemo quis sint commodi nulla praesentium recusandae.</p>
                    </div>
                </Card>
                <Card className='Card1t' style={{ width: '20%', padding: 0, height: '55vh'  }}>
                    <div className="custom-imaget">
                    <img className='Imgt' src={img4} alt=''/>
                    <p className='P2t'>PRATHNA RAO</p>
                    </div>
                    <div className="custom-cardt">
                        <h4>NAIL ARTIST</h4>
                        <p className='Pt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, libero corrupti. Nemo quis sint commodi nulla praesentium recusandae.</p>
                    </div>
                </Card>
                <Card className='Card1t' style={{ width: '20%', padding: 0, height: '55vh'  }}>
                    <div className="custom-imaget">
                    <img className='Imgt' src={img3} alt=''/>
                    <p className='P2t'>MURAL THAKUR</p>
                    </div>
                    <div className="custom-cardt">
                        <h4>HAIR ARTIST</h4>
                        <p className='Pt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, libero corrupti. Nemo quis sint commodi nulla praesentium recusandae.</p>
                    </div>
                </Card>
                </div>
    </div>
  )
}

export default Beautyteam