


// import React, { useState, useEffect } from 'react';
// import { Card, Button } from 'antd';
// import './BeautyCard.css';
// import img1 from './Image/lipstic.jpg';
// import img2 from './Image/lipglos.png';
// import img3 from './Image/Maskara.png';
// import img4 from './Image/nail2.jpeg';
// import img5 from './Image/blush.jpeg';
// import img6 from './Image/beautyprimer.png';
// import img7 from './Image/makeupproducts.png';
// import img8 from './Image/Foundationcream.png';

// function Product() {
//   const Data = [
//     { "title": "LipsticK", "img": img1 },
//     { "title": "Lipgloss", "img": img2 },
//     { "title": "Mascara", "img": img3 },
//     { "title": "Nail polish", "img": img4 },
//     { "title": "Blush", "img": img5 },
//     { "title": "Foundation", "img": img6 },
//     { "title": "Makeup Box", "img": img7 },
//     { "title": "Primer", "img": img8 }
//   ];

//   const [visibleCards, setVisibleCards] = useState(4); 
//   const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

//   useEffect(() => {
//     const handleResize = () => setIsDesktop(window.innerWidth >= 1024);
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const showMore = () => {
//     setVisibleCards(prev => Math.min(prev + 4, Data.length));
//   };

//   const showLess = () => {
//     setVisibleCards(prev => Math.max(prev - 4, isDesktop ? 8 : 4));
//   };

//   return (
//     <div>
//       <div className='main'>
//         {Data.slice(0, visibleCards).map((i, index) => (
//           <Card className='cardb' key={index}>
//             <div className="custom-image_S">
//               <img className='S_img' alt={i.title} width="100%" src={i.img} />
//             </div>
//             <div className="custom-card_S">
//               <h2 className='bn_S'>{i.title}</h2>
//             </div>
//           </Card>
//         ))}
//       </div>
//       {!isDesktop && (
//         <div className="buttons">
//           {(visibleCards < Data.length) && (
//             <Button className="button_view" onClick={showMore}>View More</Button>
//           )}
//           {(visibleCards > 4) && (
//             <Button className="button_view" onClick={showLess}>View Less</Button>
//           )}
//         </div>
//       )}
//     </div>
//   );
// }

// export default Product;

import React, { useState, useEffect } from 'react';
import { Card, Button } from 'antd';
import './BeautyCard.css';
import img1 from './Image/lipstic.jpg';
import img2 from './Image/lipglos.png';
import img3 from './Image/Maskara.png';
import img4 from './Image/nail2.jpeg';
import img5 from './Image/blush.jpeg';
import img6 from './Image/beautyprimer.png';
import img7 from './Image/makeupproducts.png';
import img8 from './Image/Foundationcream.png';
import './Beautysalon.css'

function Product() {
  const Data = [
    { "title": "Lipstick", "img": img1 },
    { "title": "Lipgloss", "img": img2 },
    { "title": "Mascara", "img": img3 },
    { "title": "Nail polish", "img": img4 },
    { "title": "Blush", "img": img5 },
    { "title": "Foundation", "img": img6 },
    { "title": "Makeup Box", "img": img7 },
    { "title": "Primer", "img": img8 }
  ];

  const [visibleCards, setVisibleCards] = useState(4); // Show 4 cards initially
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isDesktop) {
      setVisibleCards(Data.length);
    } else {
      setVisibleCards(4);
    }
  }, [isDesktop, Data.length]);

  const showMore = () => setVisibleCards(prev => Math.min(prev + 4, Data.length));
  const showLess = () => setVisibleCards(4);

  return (
    <div className='p_maindiv'>
      <h1 className='h111'>Beauty Products</h1>
      <div className='main'>
        {Data.slice(0, visibleCards).map((i, index) => (
          <Card className='cardb' key={index}>
            <div className="custom-image_S">
              <img className='S_img' alt={i.title} width="100%" src={i.img} />
            </div>
            <div className="custom-card_S">
              <h2 className='bn_S'>{i.title}</h2>
            </div>
          </Card>
        ))}
      </div>
      {!isDesktop && (
        <div className="buttons">
          {(visibleCards < Data.length) && (
            <Button className="button_view" onClick={showMore}>View More</Button>
          )}
          {(visibleCards > 4) && (
            <Button className="button_view" onClick={showLess}>View Less</Button>
          )}
        </div>
      )}
    </div>
  );
}

export default Product;
