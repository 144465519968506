
import React from 'react'
import './Appoiment.css'
import { Button } from 'antd';
// import { CiLocationOn } from "react-icons/ci";
// import { FiPhoneCall } from "react-icons/fi"
// import { MdMailOutline } from "react-icons/md";
// import { FaSquareXTwitter } from "react-icons/fa6";
// import { FaFacebook } from "react-icons/fa";
// import { IoLogoInstagram } from "react-icons/io";
// import { FaLinkedin } from "react-icons/fa";
// import { FaYoutube } from "react-icons/fa6";
function Form() {
    return (
        <div className='appoiment'>
            <br/>
            <br/>
             <h1 className='a_h1'>Make An Appointment</h1>
            <div className='a_maindiv'>
                <div className='a_Form_div' >
                    <form className='a_form' action="#">

                        <h2 className='a_leave_h'>Fill in the form below</h2>
                        <input className='a_input_i' type="text" placeholder=" Your Name" /><br />
                        <br />
                        <input className='a_input_i' type="email" placeholder="Your Email " /><br />
                        <br />



                        <input className='a_input_i' type="number" placeholder="No.of People" /><br />
                        <br />
                        <input className='a_input_i' type="date" placeholder="mm/dd/yyyy" /><br />
                        <br />
                        <input className='a_input_i' type="time" placeholder="" /><br />
                        <br />
                        <textarea className='a_input_text' placeholder="Your Message"></textarea>

                        <Button type="primary" className='a_button_get'>Submit</Button>
                        <br />
                        <br />

                    </form>

                </div>
                {/* <div className='a_Media_a'>
                    
                <p className='a_pa1'><CiLocationOn className='a_location1' />&nbsp;Lily Chowk,Puranibasti Raipur (C.G),</p>
                
                <p className='a_pa2'><FiPhoneCall className='a_phone1' />&nbsp; 222 333 4444</p>
                
                <p className='a_pa3'><MdMailOutline className='a_mail'/>&nbsp;beautysalon@gmail.com</p>
                <p><FaFacebook className='a_fb1' /><IoLogoInstagram className='a_insta2' /><FaSquareXTwitter className='a_twiter3' /><FaLinkedin className='a_in1' /><FaYoutube className='a_yt1'/></p>
       
            </div> */}
            </div>
        </div>
    )
}

export default Form