

// import React, { useState } from 'react';
// import './Navbar.css';
// import logo from './Image/beautylogo.png';

// import { Link, useNavigate } from 'react-router-dom';
// import { InstagramOutlined, YoutubeOutlined, TwitterOutlined, LinkedinOutlined, CloseOutlined, MenuOutlined } from '@ant-design/icons';

// function Navbar() {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const navigate = useNavigate();

//   const handleToggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   const handleCloseMenu = () => {
//     setMenuOpen(false);
//   };

//   const handleNavigation = (event) => {
//     const path = event.target.value;
//     if (path === 'Shop') {
//       navigate('/Shop');
//     } else if (path === 'Team') {
//       navigate('/Team');
//     } else if (path === 'Appointment') {
//       navigate('/Appointment');
//     }
//   };

//   return (
//     <div>
//       {/* <Function /> */}
//       <header className="header" id="header">
//         <nav className="nav container">
//           <img className="nav__logo" src={logo} alt="beautylogo" />

//           <div className={`nav__menu ${menuOpen ? 'show-menu' : ''}`} id="nav-menu">
//           <ul className="nav__list">
//             <li className="nav__item">
//               <a href="/" className="nav__link">
//                 <span>Home</span>
//               </a>
//             </li>
//             <li className="nav__item">
//               <a href="/About" className="nav__link">
//                 <Link to="/About"><span>AboutUs</span></Link>
//               </a>
//             </li>
//             <li className="nav__item">
//               <a href="/" className="nav__link">
//                 <span>Services</span>
//               </a>
//             </li>
//             <li className="nav__item">
           
//               <span>
//                 <select name="Shop" className="Shop_nav" onClick={handleNavigation}>
//                   <option className="op_nav" value="Shop">Shop</option>
//                   <option className="op_nav" value="Appointment">Appointment</option>
//                   <option className="op_nav" value="Team">Team</option>
//                 </select>
//               </span>
              
//             </li>
//             <li className="nav__item">
//               <a href="/" className="nav__link">
//                 <span>Package</span>
//               </a>
//             </li>
//             <li className="nav__item">
//               <a href="/" className="nav__link">
//                 <span>Contact</span>
//               </a>
//             </li>
//           </ul>

//           <div className="nav__close" id="nav-close" onClick={handleCloseMenu}>
//             <CloseOutlined className="ri-close-large-line" />
//           </div>

//           <div className="nav__social">
//             <a href="https://www.instagram.com/" target="blank" className="nav_social-link" rel="noopener noreferrer">
//               <InstagramOutlined className="ri-instagram-line" />
//             </a>
//             <a href="https://youtube.com/" target="blank" className="nav_social-link" rel="noopener noreferrer">
//               <YoutubeOutlined className="ri-github-line" />
//             </a>
//             <a href="https://twitter.com/" target="blank" className="nav_social-link" rel="noopener noreferrer">
//               <TwitterOutlined className="ri-dribbble-line" />
//             </a>
//             <a href="https://www.linkedin.com/" target="blank" className="nav_social-link" rel="noopener noreferrer">
//               <LinkedinOutlined className="ri-linkedin-box-line" />
//             </a>
//           </div>
//         </div>

//         <div className="nav__toggle" id="nav-toggle" onClick={handleToggleMenu}>
//           <MenuOutlined className="ri-menu-line" />
//         </div>
//       </nav>
//     </header>
//     </div >
//   );
// }

// export default Navbar;

import React, { useState } from 'react';
import './Navbar.css';
import logo from './Image/Beautymagic.png';
// import Function from './Function';
import { Link  , useNavigate } from 'react-router-dom';
import { InstagramOutlined, YoutubeOutlined, TwitterOutlined, LinkedinOutlined, CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Link as ScrollLink } from 'react-scroll'; 
function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleNavigation = (event) => {
    const path = event.target.value;
    if (path === 'Shop') {
      navigate('/Shop');
    } else if (path === 'Team') {
      navigate('/Team');
    } else if (path === 'Appointment') {
      navigate('/Appointment');
    }
  };

  return (
    <div>
      {/* <Function /> */}
      <header className="header" id="header">
        <nav className="nav container">
          <img className="nav__logo" src={logo} alt="beautylogo" />

          <div className={`nav__menu ${menuOpen ? 'show-menu' : ''}`} id="nav-menu">
            <ul className="nav__list">
              <li className="nav__item">
                <a href="/"  onClick={handleCloseMenu} className="nav__link">
                  <span>Home</span>
                </a>
              </li>
              <li className="nav__item">
                <a href="/About" className="nav__link">
                  <Link to="/About" style={{color:"black"}} onClick={handleCloseMenu}><span>AboutUs</span></Link>
                </a>
              </li>
              <li className="nav__item">
                <a href="/"  className="nav__link">
                  <Link to='/services' style={{color:"black"}} onClick={handleCloseMenu}><span>Services</span></Link>
                </a>
              </li>
              <li className="nav__item">
                <a href="/"  className="nav__link">
                  <Link to='/Shop'  style={{color:"black"}} onClick={handleCloseMenu}><span>Shop</span></Link>
                </a>
              </li>
              {/* <li className="nav__item">shoping
                <a href="#" className="nav__link">
                  <span>
                    <ul name="Shop" className="Shop_nav" onClick={handleNavigation}>
                      <li  onClick={handleCloseMenu} className="op_nav" value="Shop">Shop</li>
                      <li  onClick={handleCloseMenu} className="op_nav" value="Appointment">Appointment</li>
                      <li   onClick={handleCloseMenu}className="op_nav" value="Team">Team</li>
                    </ul>
                  </span>
                </a> */}
              {/* </li> */}
              <li className="nav__item">
                <a href="/" className="nav__link">
                  <Link to="/Package"  style={{color:"black"}} onClick={handleCloseMenu}><span>Package</span></Link>
                </a>
              </li>
              <li className="nav__item">
                <a href="/" className="nav__link">
                  <Link to='/appoinment'  style={{color:"black"}} onClick={handleCloseMenu}><span>Appoinment</span></Link>
                </a>
              </li>
            </ul>

            <div className="nav__close" id="nav-close" onClick={handleCloseMenu}>
              <CloseOutlined className="ri-close-large-line" />
            </div>

           
          </div>

          <div className="nav__toggle" id="nav-toggle" onClick={handleToggleMenu}>
            <MenuOutlined className="ri-menu-line" />
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Navbar;