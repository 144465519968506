import React from 'react';
import { Carousel } from 'antd';
import './crousel.css'
const Show= () => (

    <Carousel autoplay >
        
        <div className='div1'>
        <h1 className='h'>WelCome To BeautyMagic</h1>
        </div>
        <div className='div2'>
            <h1 className='h1'>Improve The Look And Feel Of Your Skin</h1>
        </div>
        <div className='div3'>
            <h1 className='h11'>Embark On A Journey Of Self-care And Elegance</h1>

        </div>
    </Carousel>
);

export default Show;