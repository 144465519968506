import React from 'react'
import 'animate.css';
import './Beautysaloan.css';
import './Responsive_beauty.css';
import back_img from './Image/b0_img.jpg'
import Image from './Image/damin_b.jpg'
import Image2 from './Image/apeksha_b.jpg'
import Image3 from './Image/prerna_b1.jpg'
import { Card } from 'antd';
import './Beautyteam.css'
import img1 from './Image/a1.png'
import img2 from './Image/a2.jpeg'
import img3 from './Image/a3.jpeg'
import img4 from './Image/a4.jpeg'
const Beautysaloan = () => {
  return (
    <div>
      <div className='about_main1'>
        {/* <br /> */}
        <img src={back_img} alt="" className='back_img' />
        {/* <h1 className='about_header1'>About Us</h1> */}
        <div className='about_div1'>

          <h1 className='beautyheader'>Our mission</h1>
          <br />
          {/* <h4> Welcome To Beauty Saloan</h4> */}
          <p className='about_para'>Our mission is to empower our clients by enhancing their natural beauty. We believe that everyone deserves to feel beautiful and confident, and we are committed to providing the highest quality beauty services to achieve this.
            Our team of skilled professionals is dedicated to creating a welcoming and inclusive environment where every client feels valued and cared for. We strive to stay ahead of beauty trends and continuously improve our services to meet our clients’ evolving needs. Above all, we aim to provide an exceptional beauty experience that leaves our clients feeling rejuvenated and confident in their own skin.</p>
        </div>
      </div>
      <div className='team_div'>
        <h1 className='team'>Our Beauty Team</h1>
        <div className='maint'>
          <Card className='Card1t' >
            <div className="custom-imaget">
              <img className='Imgt' src={img1} alt='' />
              <p className='P2t'>SONAL SINGH</p>
            </div>
            <div className="custom-cardt">
              <p className='s_heading'>BEAUTICIAN</p>
              <p className='Pt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, libero corrupti. Nemo quis sint commodi nulla praesentium recusandae.</p>
            </div>
          </Card>
          <Card className='Card1t' >
            <div className="custom-imaget">
              <img className='Imgt' src={img2} alt='' />
              <p className='P2t'>AHUTI SONI</p>
            </div>
            <div className="custom-cardt" >
              <p className='s_heading'>MAKEUP ARTIST</p>
              <p className='Pt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, libero corrupti. Nemo quis sint commodi nulla praesentium recusandae.</p>
            </div>
          </Card>
          <Card className='Card1t' >
            <div className="custom-imaget">
              <img className='Imgt' src={img4} alt='' />
              <p className='P2t'>PRATHNA RAO</p>
            </div>
            <div className="custom-cardt">
              <p className='s_heading'>NAIL ARTIST</p>
              <p className='Pt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, libero corrupti. Nemo quis sint commodi nulla praesentium recusandae.</p>
            </div>
          </Card>
          <Card className='Card1t' >
            <div className="custom-imaget">
              <img className='Imgt' src={img3} alt='' />
              <p className='P2t'>MURAL THAKUR</p>
            </div>
            <div className="custom-cardt">
              <p className='s_heading'>HAIR ARTIST</p>
              <p className='Pt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, libero corrupti. Nemo quis sint commodi nulla praesentium recusandae.</p>
            </div>
          </Card>
        </div>
      </div>
      <div className='about_main2'>
        <h1 className='about_main2_h1'>Meet Our Founders</h1>
        <div className='main2_div1'>
          <div className='founder1'>
            <img src={Image} alt="" className='f1_img' />

            <h2 className='main2_div1_h2'>Damin Yadav</h2>
            <h3 className='main2_div1_h3'>Founder Of Beauty Salon</h3>
          </div>
          <div className='founder2'>
            <img src={Image2} alt="" className='f2_img' />

            <h2 className='main2_div1_h2'>Apeksha Tebhurane</h2>
            <h3 className='main2_div1_h3'>Co-Founder Of Beauty Salon</h3>
          </div>
          <div className='founder3'>
            <img src={Image3} alt="" className='f3_img' />
            <h2 className='main2_div1_h2'>Prerna Vinayak</h2>
            <h3 className='main2_div1_h3'>CEO Of Beauty Salon</h3>
          </div>
          <div className='founder4'>
            <img src={Image3} alt="" className='f3_img' />
            <h2 className='main2_div1_h2'>Harsha Yadav</h2>
            <h3 className='main2_div1_h3'>Director Of Beauty Salon</h3>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Beautysaloan